@import '~bootstrap/dist/css/bootstrap.min.css';

body {
  margin: 0;
  font-family: "Nasa", "Inter", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: "Nasa";
  src: local("Nasa.ttf"),
    url("./fonts/Nasa.ttf") format("truetype");
}