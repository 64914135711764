.menu-button
{
    background-color: inherit;
    color: rgb(59, 56, 56);
    border: 0;
    min-width: fit-content;
    font-size: clamp(16px, 2vw, 20px);
    text-decoration: none;
}

.menu-button:hover {
    color: #6c757d;
}

.menu-button:active
{
    color: #6c757d;
}