.home-font {
    font-family: 'Inter', sans-serif;
}

.quote-paragraph {
    font-size: clamp(14px, 1vw, 22px);
}

.about-paragraph {
    margin-bottom: 4%;
    font-size: clamp(12px, 1vw, 22px);
}

.about-list-items {
    font-size: clamp(12px, 1vw, 22px);
}

.contact-font {
    font-family: 'Inter', sans-serif;
    font-size: clamp(15px, 1vw, 22px);
}

.contact-title-font {
    font-family: 'Inter', sans-serif;
    font-size: clamp(22px, 2vw, 30px);
}

.inquiry-form-title {
    font-family: 'Inter', sans-serif;
    font-size: clamp(18px, 2vw, 26px);
}

.image {
    transition: filter 0.5s ease;
}

.image:hover {
    filter: grayscale(100%);
}

.copy-right-font {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
}

.submit-button {
    background-color: inherit;
    color: rgb(59, 56, 56);
    border: 0;
    min-width: fit-content;
    text-decoration: none;
    font-size: clamp(16px, 2vw, 20px);
}

.submit-button:hover {
    color: #6c757d;
}

.submit-button:active {
    color: #6c757d;
}

.contact-input {
    border-radius: 0px !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid black !important;
    background-color: inherit !important;
    color: black !important;
}

.contact-input:focus{
    box-shadow: none !important;
}